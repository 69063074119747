import { Link } from 'gatsby';

import React from 'react';

import styled from 'styled-components';

import Layout from '../../shared/components/Layouts/Inner';
import Content from '../../shared/components/Content';
import SEO from '../../shared/components/SEO';
import ServicesLinks from '../../shared/components/Services/ServicesLinks';

import ContainerFixed from '../../shared/components/Layouts/ContainerFixed';
import Section from '../../shared/components/Section';

import Hero from '../../shared/components/Hero/index';
import HeroImage from '../../shared/images/banners/newcastle-station.jpg';
import ServicesList from '../../shared/data/services.json';

import PressReviews from '../../shared/components/PressReviews';
import Testimonials from '../../shared/components/Testimonial/Testimonials';
import LocationsList from '../../shared/components/Locations/List';
import AppDownload from '../../shared/components/AppDownload';
import AboutUs from '../../shared/components/About';
import ContactDetails from '../../shared/components/ContactDetails';

const ServiceCta = styled(Link)`
  display: inline-block;
  background-color: ${(props) => props.theme.primary};
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  font-size: 80%;
  margin: 0 0.5rem;

  @media (max-width: 990px) {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 500px) {
    width: 75%;
  }

  &:hover {
    background-color: #ffffff;
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;

const Services = () => (
  <Layout darkMode url="cleaning-services">
    <SEO title="Cleaning Services" />
    <Hero image={HeroImage} heading="Cleaning Services" hideBookBtn hideServicesBtn>
      <p>
        We are the North East's independently rated premier cleaning company for both 2018 and 2019.
        We provide domestic and commercial cleaning solutions you can trust at your fingertips.
      </p>
      <p>
        {ServicesList.map((service) => (
          <ServiceCta to={`/cleaning-services/${service.slug}`} key={service.id}>
            {service.name}
          </ServiceCta>
        ))}
      </p>
      <br />
    </Hero>
    <PressReviews />
    <ServicesLinks />

    <ContainerFixed>
      <Testimonials />
    </ContainerFixed>

    <Section>
      <h2>Our Cleaning Services</h2>

      <p>
        We believe in offering customer friendly cleaning services to suit all domestic and
        commercial cleaning needs. Quality assurance is extremely important to us so we provide
        professionally trained, vetted and experienced maids that come fully equipped and insured.
      </p>

      <h2>Why complicate things?</h2>

      <p>
        Choosing a reputable cleaning company that you can trust has never been simpler. Bookings
        can be made online on our secure state of the art booking system or if you prefer our
        friendly customer service team are happy to help via email or give us a call.
      </p>

      <ContactDetails />

      <div style={{ fontSize: '90%' }}>
        <p>
          All bookings are contract free and may be managed at your fingertips on our user friendly
          Launch27 App.
        </p>
        <p> Perfect for modern busy lifestyles.</p>
      </div>
    </Section>

    <br />

    <LocationsList />

    <ContainerFixed>
      <AppDownload />
      <Content>
        <AboutUs />
      </Content>
    </ContainerFixed>
  </Layout>
);

export default Services;
